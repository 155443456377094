<template>
  <button class="btn expand" @click="handleClick">
    <span class="text">
      {{ expanded ? $t("COMMON.REDUCE_MODAL") : $t("COMMON.EXPAND_MODAL") }}
    </span>
    <span class="icon">
      <img
          src="../../public/img/kw-expand.svg"
          alt="icon"
      />
    </span>
  </button>
</template>
<script>
export default {
  name: "wrapper-expand-button",
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    handleClick(evt) {
      this.expanded = !this.expanded;
      this.$emit("click", evt);
    },
  },
};
</script>
<style></style>
